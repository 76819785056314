import { StockStatus } from '~/shared/stock-status/stock-status.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import { Basket } from '~/models/basket';
import { useMemo } from 'react';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';

type ProductItemStockStatusProps = {
    itemNo?: string;
    stock?: Basket.IStockStatusDto;
    caption: string;
    captionClassName?: string;
};

export function ProductItemStockStatus({ itemNo, stock, caption, captionClassName }: ProductItemStockStatusProps) {
    const { data } = useStockStatus(itemNo ? [itemNo] : undefined);

    const stockStatus = useMemo(() => {
        if (!itemNo || !data) {
            return stock;
        }

        return {
            // The stock query is combined with the validated stock from the basket query.
            // Displaying the stock from the basket query ensures that the stock status changes based on the quantity.
            // The stock query contains general data about the stock, used to display the tooltip and the modal.
            ...data[itemNo],
            ...stock,
        };
    }, [data, itemNo, stock]);

    return (
        <ValueWithCaption caption={caption} captionClassName={captionClassName}>
            <StockStatus state="ready" stock={stockStatus} />
        </ValueWithCaption>
    );
}
