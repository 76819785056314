import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import useUser from '~/libs/use-user';
import { useLayoutState } from '~/context/layout.context';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import { useMemo } from 'react';

export function useVehicleDetails(licensePlate?: VehicleTypes.ILicensePlate, carId?: string | number, vin?: string) {
    const { licensePlateCountry } = useLayoutState();
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();

    const { vehicles, createUrl } = useEndpoints();

    const getEndpoint = useMemo(() => {
        if (licensePlate?.number) {
            return `${vehicles.getVehicleInfoByLicensePlateOrVin}/${licensePlate.number}/details`;
        }
        if (vin) {
            return `${vehicles.getVehicleInfoByLicensePlateOrVin}/${vin}/details`;
        }
        if (carId && carId !== '0') {
            return `${vehicles.getVehicleInfoByCarId}/${carId}/details`;
        }

        return '';
    }, [licensePlate?.number, vin, carId]);

    const url = createUrl({
        localeOption: LocaleOptions.path,
        endpoint: getEndpoint,
        query: { licensePlateCountry },
    });

    return useQuery<VehicleTypes.IVehicleDetails, Error>({
        queryKey: queryKeys.vehicle.vehicleDetails(locale, licensePlateCountry, licensePlate?.number, vin, carId),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        enabled: isLoggedIn && !!(licensePlate?.number ?? carId),
        keepPreviousData: true,
    });
}
