import React, { useEffect, useState } from 'react';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import WithOverviewNavigation from '../overview-navigation/overview-navigation.hoc';
import { Orders as OrdersTypes } from '~/models/orders';
import InvoiceList from './invoice-list/invoice-list.component';
import useInfiniteLoading from '~/shared/hooks/use-infinite-loading';
import OverviewLoadMore from '~/page-elements/overview/common/overview-load-more/overview-load-more.component';
import Filters from '~/page-elements/overview/common/filters/filters.component';
import Header from '../common/header/header.component';
import Deposits from './deposits/deposits.component';
import { useLayoutState } from '~/context/layout.context';
import { DEFAULT_PAGING_SIZE } from '~/constants/settings';
import { useInvoiceLayoutState } from '~/widgets/overview/invoices-widget/invoices.context';
import { useCreditDraftStorage } from '~/widgets/overview/invoices-widget/invoice-draft/hooks';
import { InvoiceDraft } from '~/widgets/overview/invoices-widget/invoice-draft/invoice-draft.component';

//Name before: invoices.component.tsx
function W060InvoicesOverview() {
    const { orders } = useEndpoints();
    const { selectedDepartment } = useLayoutState();
    const [endpoint, setEndpoint] = useState<string>(`${orders.invoices}/all`);
    const { createInvoice } = useInvoiceLayoutState();
    const [pageSize, setPageSize] = useState(DEFAULT_PAGING_SIZE);
    const [{ entries: storedEntries }] = useCreditDraftStorage();

    const {
        data: invoices,
        isLoadingInitialData,
        isLoadingMore,
        total,
        loaded,
        error,
        loadMore,
        fromDate,
        toDate,
        setSearchVal,
        searchVal,
        setDate,
    } = useInfiniteLoading({
        pathName: endpoint,
        key: 'invoices',
        localeOption: LocaleOptions.path,
        query: { departmentId: selectedDepartment?.id },
        paginationLimit: pageSize,
    });

    useEffect(() => {
        setEndpoint(searchVal ? `${orders.invoices}/search` : `${orders.invoices}/all`);
    }, [searchVal]);

    return (
        <>
            <Deposits />
            <Header>
                <Filters
                    fromDate={fromDate}
                    toDate={toDate}
                    setDate={setDate}
                    query={searchVal}
                    setQuery={setSearchVal}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    hidePageSizeFilter={false}
                />
            </Header>

            {createInvoice || storedEntries.length ? <InvoiceDraft /> : null}

            <InvoiceList
                error={error}
                searchQuery={searchVal}
                invoices={invoices as OrdersTypes.IInvoiceViewModel[]}
                fetching={isLoadingInitialData}
            />

            {invoices?.length ? <OverviewLoadMore total={total} loaded={loaded} onClick={loadMore} fetching={isLoadingMore} /> : null}
        </>
    );
}

export default WithOverviewNavigation(W060InvoicesOverview);
