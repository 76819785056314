import React, { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import styles from './stock-status.module.scss';
import { Products as ProductTypes } from '~/models/products.d';
import QuickView from './quick-view/quick-view.component';
import Skeleton from '~/shared/skeleton';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import Tooltip, { TooltipContentWrapper } from '../tooltip/tooltip.component';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import { useStockDeliveryTime } from '~/shared/stock-status/hooks';

export type StockState = 'loading' | 'ready' | 'error';

export type UseStockStateOptions = {
    loading: boolean;
    error: boolean;
};

/**
 * Takes isError and isLoading from react-query and converts them to a fitting StockStatus state prop
 */
export function getStockStatusComponentState({ loading, error }: UseStockStateOptions): StockState {
    if (loading) {
        return 'loading';
    }

    if (error) {
        return 'error';
    }

    return 'ready';
}

export type StockBadgeProps = {
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    status?: ProductTypes.StockStatusCode;
    disabled?: boolean;
    children?: string;
};

export function StockBadge({ className, onClick, status, children, disabled = false }: StockBadgeProps) {
    return (
        <button type="button" onClick={onClick} disabled={disabled} className={clsx(styles.button, styles[`status-${status}`], className)}>
            {status === ProductTypes.StockStatusCode.NotInStock ? <Svg name="customer-service" thick className={styles.buttonIcon} /> : null}

            {children ? <span className={styles.text}>{children}</span> : null}
        </button>
    );
}

export type StockTooltipProps = {
    className?: string;
    itemNo?: string;
    stockStatus?: ProductTypes.StockStatusCode;
    state?: StockState;
    label?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const StockTooltip = React.forwardRef<HTMLSpanElement, StockTooltipProps>(
    ({ itemNo, label, className, onClick, state = 'ready', stockStatus }, ref) => {
        return (
            <span ref={ref}>
                {state === 'loading' ? (
                    <Skeleton style={{ height: 15, width: 90 }} />
                ) : (
                    <Tooltip delayShow={300} content={<TooltipContentWrapper>{itemNo ? <QuickView itemNo={itemNo} /> : null}</TooltipContentWrapper>}>
                        <StockBadge className={className} onClick={onClick} disabled={['loading', 'error'].includes(state)} status={stockStatus}>
                            {label}
                        </StockBadge>
                    </Tooltip>
                )}
            </span>
        );
    },
);

export type StockStatusProps = {
    className?: string;
    state: StockState;
    stock?: ProductTypes.IStockStatus;
    group?: string;
};

const StockModal = dynamic(() => import('./stock-modal').then((module) => module.StockModal));

export function StockStatus({ className, state, stock, group }: StockStatusProps) {
    const translate = useTranslations();

    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setModalOpen(false);
    }, []);

    const handleClick = useCallback(() => {
        setModalOpen(true);
    }, []);

    const label = useStockDeliveryTime({
        time: stock?.deliveryTime ? String(stock?.deliveryTime) : undefined,
        fallback: stock?.statusTranslation,
    });

    return (
        <div>
            {stock?.variantId && isModalOpen ? (
                <StockModal
                    itemNo={stock?.variantId}
                    header={
                        <>
                            {translate('stockStatus.stockStatus', 'Lagerstatus')}
                            <Text textStyle="monoMedium">{stock?.variantId}</Text>
                            {group && (
                                <Text textStyle="bodySmall">
                                    {`${translate('stockStatus.subgroup', 'Undergruppe')}: `}
                                    <Text textStyle="monoMedium" tagName="span">
                                        {group}
                                    </Text>
                                </Text>
                            )}
                        </>
                    }
                    onOpenChange={handleOpenChange}
                    open={isModalOpen}
                />
            ) : null}
            <StockTooltip
                className={className}
                itemNo={stock?.variantId}
                stockStatus={stock?.statusCode}
                state={state}
                onClick={handleClick}
                label={label}
            />
        </div>
    );
}

export type StockStatusWithStockProps =
    // The `state` and `stock` prop is omitted since it will be controlled by the stock query status and result.
    Omit<StockStatusProps, 'state' | 'stock'> & {
        itemNo?: string;
    };

/**
 * HOC used for enriching a StockStatus presentational component with a stock status query for a single product.
 * When using this utility, the state of the StockStatus component is uncontrolled.
 */
export function withStockStatusQuery(Component: typeof StockStatus) {
    return function StockStatusWithStockQuery({ itemNo, ...props }: StockStatusWithStockProps) {
        const { data = {}, isLoading, isError } = useStockStatus(itemNo ? [itemNo] : []);

        const stock = itemNo && data ? data[itemNo] : undefined;

        const stockState = getStockStatusComponentState({
            error: isError,
            loading: isLoading,
        });

        return <Component state={stockState} stock={stock} {...props} />;
    };
}

/**
 * Use this component if there's a need for displaying the stock status and modal,
 * while also fetching a single stock status based on the item no.
 */
export const StockStatusWithStockQuery = withStockStatusQuery(StockStatus);
